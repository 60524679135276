import React, { useEffect } from 'react';
import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import {
  dateOfBirthLabel,
  emailLabel,
  enabledLabel,
  loginLabel,
  middleNameLabel,
  nameLabel,
  phoneNumberLabel,
  roleLabel,
  selectRolePlaceholder,
  surnameLabel,
} from './helper';
import moment from 'moment/moment';
import { emailPattern } from '../../../../../constants/regex';
import InputMask, { ReactInputMask } from 'react-input-mask';
import _, { isEmpty } from 'lodash';
import {
  createUser,
  fetchRoles,
  fetchUserList,
  fetchUserSettings,
  selectRoleList,
  selectUserSettings,
  updateUser,
} from '../../../settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FieldNames, Permissions, Sources, User } from '../../../types/user';
import { useIntl } from 'react-intl';
import { setError } from '../../../../error/errorSlice';
import { hasAccess } from '../../../../../utils/rights';
import {
  selectCurrentUser,
  selectCurrentUserGrants,
} from '../../../../user/userSlice';
import {
  birthDateFormat,
  isoDateFormat,
} from '../../../../../utils/dateFormats';
import styles from '../styles.module.less';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface FieldValue {
  errors?: string[] | undefined;
  warnings?: string[] | undefined;
  name: string | number | (string | number)[];
  touched?: boolean;
  validating?: boolean;
  value?: any;
}

interface IAddUserFormProps {
  form: any;
  openedUser: User | null;
  onClose: () => void;
  onSaveSuccess?: () => void;
  isUserEditing: boolean;
}

const AddUserForm = ({
  form,
  openedUser,
  onClose,
  onSaveSuccess,
  isUserEditing = false,
}: IAddUserFormProps) => {
  const dispatch = useDispatch();
  const roles = useSelector(selectRoleList);
  const userSettings = useSelector(selectUserSettings);
  const currentUser = useSelector(selectCurrentUser);
  const intl = useIntl();
  const userGrants = useSelector(selectCurrentUserGrants);
  const requiredFieldMessage = intl.formatMessage({
    id: 'Common.requiredField',
    defaultMessage: 'Обязательное поле',
  });

  const isMyProfile = openedUser?.id === currentUser?.id;

  const hasAccessToEdit = hasAccess([Permissions.USER_EDIT], userGrants);

  const onFinishFailed = (errorInfo: any) => {
    dispatch(setError(errorInfo));
  };

  const profile = openedUser?.profile;
  const onFinish = (values: User) => {
    const preparedValues = {
      ...values,
      profile: {
        ...values.profile,
        birthDay: values.profile?.birthDay
          ? moment(values.profile?.birthDay).format(isoDateFormat)
          : '',
      },
    };
    if (isUserEditing) {
      const updatedUser = _.merge({}, openedUser, preparedValues); // merge id's fields to form fields. form send values without id's (only described fields)
      dispatch(
        updateUser(updatedUser, () => {
          onClose();
          dispatch(fetchUserList({ size: 0, page: 0 }));
          if (onSaveSuccess) {
            onSaveSuccess();
          }
        }),
      );
    } else {
      dispatch(
        createUser(preparedValues, () => {
          onClose();
          dispatch(fetchUserList({ size: 0, page: 0 }));
        }),
      );
    }
  };

  const initialOpenedUser = {
    ...openedUser,
    profile: {
      ...profile,
      birthDay: profile?.birthDay ? moment(profile?.birthDay) : '',
    },
  };

  const handleChange = ([{ value, name }]: FieldValue[]) => {
    if (typeof value === 'string') {
      const formattedValue = value.replace(/^\s+/g, '');
      form.setFieldValue(name, formattedValue);
    }
  };

  const blurHandle = (names: FieldValue['name']) => {
    form.setFieldValue(names, (form.getFieldValue(names) || '').trim());
  };

  useEffect(() => {
    if (isEmpty(userSettings)) {
      dispatch(fetchUserSettings());
    }
  }, []);

  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchRoles());
    }
  }, []);

  const isKcSource = (key: FieldNames) =>
    userSettings[key]?.source === Sources.KC;

  const isRequired = (key: FieldNames) => ({
    required: userSettings[key]?.required || false,
  });

  return (
    <Form
      {...layout}
      name="basic"
      form={form}
      initialValues={{ ...initialOpenedUser }}
      onFinish={onFinish}
      onFieldsChange={handleChange}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={surnameLabel}
        name={['profile', 'lastName']}
        rules={[
          { message: requiredFieldMessage, ...isRequired(FieldNames.lastName) },
        ]}
      >
        <Input
          disabled={isKcSource(FieldNames.lastName)}
          onBlur={() => blurHandle(['profile', 'lastName'])}
        />
      </Form.Item>

      <Form.Item
        label={nameLabel}
        name={['profile', 'firstName']}
        rules={[
          {
            message: requiredFieldMessage,
            ...isRequired(FieldNames.firstName),
          },
        ]}
      >
        <Input
          disabled={isKcSource(FieldNames.firstName)}
          onBlur={() => blurHandle(['profile', 'firstName'])}
        />
      </Form.Item>

      <Form.Item
        label={middleNameLabel}
        name={['profile', 'middleName']}
        rules={[
          {
            message: 'Please input your username!',
            ...isRequired(FieldNames.middleName),
          },
        ]}
      >
        <Input
          disabled={isKcSource(FieldNames.middleName)}
          onBlur={() => blurHandle(['profile', 'middleName'])}
        />
      </Form.Item>

      <Form.Item
        label={loginLabel}
        name="username"
        rules={[
          {
            message: requiredFieldMessage,
            ...isRequired(FieldNames.username),
          },
        ]}
      >
        <Input
          onBlur={() => blurHandle(['username'])}
          disabled={isKcSource(FieldNames.username)}
        />
      </Form.Item>

      <Form.Item
          className={styles.selectRoleInput}
        {...tailLayout}
        name="roleId"
        label={roleLabel}
        rules={[
          { ...isRequired(FieldNames.roles), message: requiredFieldMessage },
        ]}
      >
        <Select
          disabled={!hasAccessToEdit || isKcSource(FieldNames.roles)}
          placeholder={selectRolePlaceholder}
        >
          {roles.map(({ name, id }) => (
            <Select.Option value={id} key={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={dateOfBirthLabel}
        name={['profile', 'birthDay']}
        rules={[{ ...isRequired(FieldNames.birthDay) }]}
      >
        <DatePicker
          disabled={isKcSource(FieldNames.birthDay)}
          format={birthDateFormat}
        />
      </Form.Item>

      <Form.Item
        label={emailLabel}
        name={['profile', 'email']}
        rules={[
          {
            ...isRequired(FieldNames.email),
            message: requiredFieldMessage,
            ...(!isUserEditing && { pattern: emailPattern }),
          },
        ]}
      >
        <Input
          onBlur={() => blurHandle(['profile', 'email'])}
          disabled={isKcSource(FieldNames.email)}
        />
      </Form.Item>

      <Form.Item
        rules={[{ ...isRequired(FieldNames.phone) }]}
        label={phoneNumberLabel}
        name={['profile', 'phone']}
      >
        <InputMask
          disabled={isKcSource(FieldNames.phone)}
          mask="+7 (999) 999-99-99"
        >
          {(inputProps: ReactInputMask) => (
            <Input disabled={isKcSource(FieldNames.phone)} {...inputProps} />
          )}
        </InputMask>
      </Form.Item>

      {!isMyProfile && (
        <Form.Item
          rules={[{ ...isRequired(FieldNames.enabled) }]}
          label={enabledLabel}
          name="enabled"
          valuePropName="checked"
        >
          <Checkbox
            disabled={!hasAccessToEdit || isKcSource(FieldNames.enabled)}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default AddUserForm;
