import { intl } from '../../utils/intl';

const unauthorized = intl.formatMessage({
  id: 'error.unauthorized',
  defaultMessage: 'Ошибка авторизации',
});

const defaultErrorMessage = intl.formatMessage({
  id: 'error.error',
  defaultMessage: 'Произошла ошибка',
});

export const getErrorMessage = (
  errorKey: string,
  errorMessage: string | null,
) => {
  switch (errorKey) {
    case 'unauthorized':
      return unauthorized;
    default:
      return errorMessage || defaultErrorMessage;
  }
};
