export enum LocaleEnum {
  DE = 'DE',
  RU = 'RU',
  EN = 'EN',
}

export type ReceivedConfigType = {
  localisation: LocaleEnum[];
  passwordMinLength: number;
  auth: {
    url: string;
    realm: string;
    clientId: string;
    scopes: string;
  };
};

export type GlobalConfig = {
  backend: string;
  receivedConfig: ReceivedConfigType;
};

export const DEFAULT_BACKEND = 'https://dash-dev.dc.oswfm.ru/';

const url = new URL(window.location.href).host;

export const host = !url.includes('localhost') ? url : DEFAULT_BACKEND;
