import React, { useCallback, useEffect, useRef, useState } from 'react';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';
import { Dashboard, DataType, WidgetGrid } from '../../types/dashboard';
import cn from 'classnames';
import styles from './Dashboard.module.less';
import DashSkeleton from '../../../../components/DashSkeleton';
import {
  checkRectanglesOverlap,
  getLayout,
  isActiveWidgetGrid,
  updateDashboardGridPositions,
} from './helper';
import WidgetPropsDrawer from '../../../../components/WidgetPropsDrawer';
import WidgetGridItem from '../../../../components/WidgetGridItem';
import PrintModal from './PrintModal';
import { WidgetTypes } from '../../../../components/Widget/types/common';
import { CreateWidgetGridOptions } from '../../types/widget';
import {
  addWidget,
  deleteWidgetError,
  receiveWidgetsData,
  selectDraggedWidgetType,
  setWidgetError,
} from '../../dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  createWidgetGrid,
  widgetInitialParams,
} from '../../../../components/Widget/helper';
import { fetchSSe } from '../../../../utils/sseApi';
const GridLayout = WidthProvider(RGL);

interface DashboardFormProps {
  dashboard: Dashboard;
  isEditable: boolean;
  loading: boolean;
  onChange: (dashboard: Dashboard) => void;
  onSelectWidgetGrid: (widgetGrid: WidgetGrid) => void;
  activeWidgetGrid: WidgetGrid | null;
  onWidgetUpdate: (widgetGrid: WidgetGrid) => void;
  onDeleteWidgetGrid: (widgetId: string) => void;
  onChangeWidgetParameter: (widgetId: string, value: any) => void;
  printOpen: boolean;
  setPrintOpen: (printOpen: boolean) => void;
}

const OUTSIDE_DROPPING_ID = 'dropping_new_widget';
const THRESHOLD_OVERLAP_PIXELS = 50;

const DashboardForm: React.FC<DashboardFormProps> = ({
  dashboard,
  loading,
  isEditable,
  onChange,
  onSelectWidgetGrid,
  activeWidgetGrid,
  onWidgetUpdate,
  onDeleteWidgetGrid,
  onChangeWidgetParameter,
  printOpen,
  setPrintOpen,
}) => {
  const dispatch = useDispatch();
  const draggedWidgetType = useSelector(selectDraggedWidgetType);
  const [widgetPropsOpen, setWidgetPropsOpen] = useState<boolean>(false);
  const [preventCollision, setPreventCollision] = useState(true);
  const widgetRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [ctrl, setCtrl] = useState(new AbortController());

  const abortSse = () => {
    ctrl.abort();
    setCtrl(new AbortController());
  };

  const toggleWidgetPropsOpen = useCallback(() => {
    setWidgetPropsOpen(!widgetPropsOpen);
  }, [widgetPropsOpen, setWidgetPropsOpen]);

  const handleDeleteWidgetGrid = useCallback(
    (widgetId: string) => {
      onDeleteWidgetGrid(widgetId);
      setWidgetPropsOpen(false);
    },
    [onDeleteWidgetGrid, setWidgetPropsOpen],
  );

  const updateDashboardLayout = (layout: Layout[]): void => {
    const newDashboard: Dashboard = updateDashboardGridPositions(
      dashboard,
      layout,
    );

    onChange(newDashboard);
  };

  const onDrop = ({
    e,
    ...position
  }: {
    x: number;
    y: number;
    w: number;
    h: number;
    e: Event;
  }) => {
    if (draggedWidgetType) {
      const options: CreateWidgetGridOptions = {
        widgetOptions: { type: draggedWidgetType },
        position,
      };
      const widgetGrid = createWidgetGrid(options);

      dispatch(addWidget(dashboard, widgetGrid));
    }
  };

  const handleDragStop = (layout: Layout[]) => updateDashboardLayout(layout);

  const handleResizeStop = (layout: Layout[]) => updateDashboardLayout(layout);

  const initialSelected = {
    name: dashboard.name,
    id: dashboard.id,
    dataType: DataType.Dashboard,
  };

  const [selectedItem, setSelectedItem] = useState<{
    name: string;
    id: string;
    dataType: DataType;
    widgetType?: WidgetTypes;
  }>(initialSelected);

  const onPrintClose = () => {
    setPrintOpen(false);
    setSelectedItem(initialSelected);
  };

  const droppingItem = draggedWidgetType
    ? {
        ...widgetInitialParams[draggedWidgetType].position,
        i: OUTSIDE_DROPPING_ID,
      }
    : undefined;

  const handleOverLap = () => {
    const widgetsPositions = widgetRefs.current.map(el =>
      el?.getBoundingClientRect(),
    );

    const hasOverLap = checkRectanglesOverlap(
      widgetsPositions as DOMRect[],
      THRESHOLD_OVERLAP_PIXELS,
    );
    setPreventCollision(!hasOverLap);
  };

  useEffect(() => {
    if (!isEditable) {
      fetchSSe(`/api/widget/stream/${dashboard.id}/all`, {
        ctrl,
        onmessage(ev) {
          const data = JSON.parse(ev.data);
          if (data?.error) {
            const errorMessage = data?.error || '';
            dispatch(setWidgetError({ id: data?.id, message: errorMessage }));
          } else {
            dispatch(deleteWidgetError(data.id));
            dispatch(
              receiveWidgetsData({
                widgetId: data?.id,
                data: data?.data,
              }),
            );
          }
        },
      });
    }
  }, [isEditable]);

  useEffect(() => {
    if (isEditable) {
      abortSse();
    }
  }, [isEditable]);

  useEffect(() => () => abortSse(), []);

  if (loading) {
    return <DashSkeleton />;
  }

  return (
    <div className={styles.container}>
      <PrintModal
        isOpen={printOpen}
        onClose={onPrintClose}
        selected={selectedItem}
      />
      <div className={styles.gridWrap}>
        <GridLayout
          containerPadding={[0, 0]}
          className={cn('layout', { [styles.grid]: isEditable })}
          draggableHandle=".react-grid-drag-space"
          layout={getLayout(dashboard)}
          cols={120}
          rowHeight={10}
          isDraggable={isEditable}
          isDroppable={isEditable}
          preventCollision={preventCollision}
          onDrop={onDrop}
          droppingItem={droppingItem}
          onDrag={handleOverLap}
          isResizable={isEditable}
          onDragStop={handleDragStop}
          onResize={handleOverLap}
          onResizeStop={handleResizeStop}
        >
          {dashboard.widgetGrids.map((widgetGrid, i) => {
            const isActiveWidget = isActiveWidgetGrid(
              widgetGrid,
              activeWidgetGrid,
            );

            const editableStyles = cn(styles.widgetGridItemWrap, {
              [styles.editableWidgetGridItem]: isEditable,
            });

            const onWidgetClick = () => {
              setSelectedItem({
                name: widgetGrid.widget.name,
                dataType: DataType.Widget,
                id: widgetGrid.widget.id || '',
                widgetType: widgetGrid.widget.type,
              });
              setPrintOpen(true);
            };

            return (
              <div
                key={widgetGrid.id}
                ref={el => (widgetRefs.current[i] = el)}
                className={editableStyles}
              >
                <WidgetGridItem
                  onWidgetActionsClick={onWidgetClick}
                  key={widgetGrid.id}
                  widgetGrid={widgetGrid}
                  editable={isEditable}
                  onSelect={onSelectWidgetGrid}
                  active={isActiveWidget}
                  onEditBtnClick={toggleWidgetPropsOpen}
                  onChangeWidgetParameter={onChangeWidgetParameter}
                />
              </div>
            );
          })}
        </GridLayout>
      </div>
      {isEditable && activeWidgetGrid && (
        <WidgetPropsDrawer
          widgetGrid={activeWidgetGrid}
          onSubmit={onWidgetUpdate}
          onDeleteWidgetGrid={handleDeleteWidgetGrid}
          open={widgetPropsOpen}
          onClose={toggleWidgetPropsOpen}
        />
      )}
    </div>
  );
};

export default DashboardForm;
