import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getError,
  clearError,
  getErrorMessage as getErrorMessageSelector,
} from '../../features/error/errorSlice';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import { accessProhibited } from '../../utils/errors';
import { getErrorMessage } from './helper';

const ErrorMessenger: React.FC = () => {
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const errorMessage = useSelector(getErrorMessageSelector);

  useEffect(() => {
    if (error) {
      if (error !== accessProhibited) {
        message.error(getErrorMessage(error, errorMessage));
      }
      dispatch(clearError());
    }
  }, [error, dispatch]);

  return <React.Fragment />;
};

export default ErrorMessenger;
