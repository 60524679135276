import { Widget } from './widget';

export interface WidgetPosition {
  x: number;
  y: number;
  w: number;
  h: number;
}

export type WidgetsDataType = any; // TODO типизировать данные виджетов

export interface WidgetGrid {
  id?: string;
  position: WidgetPosition;
  data?: WidgetsDataType;
  widget: Widget;
}

export interface Owner {
  id: string;
  name: string;
}

export interface Dashboard {
  id: string;
  name: string;
  owner?: Owner;
  widgetGrids: WidgetGrid[];
}

export interface DashboardListItem {
  id: string;
  name: string;
  owner?: Owner;
  widgetCount: number;
}

export enum DataType {
  Dashboard = 'dashboard',
  Widget = 'widget',
}

export enum FileType {
  PNG = 'PNG',
  PDF = 'PDF',
  XLSX = 'XLSX',
}

export enum ContactTypes {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum Frequency {
  DAY = 'DAILY',
  WEEK = 'WEEKLY',
  MONTH = 'MONTHLY',
}

export enum MonthPeriods {
  LAST_DAY = 'LAST_DAY',
}

export interface ContactParamType {
  value: string;
}

export interface TemplateType {
  id: string;
  name: string;
  createDate: string;
  author: string;
  contacts: { value: string }[];
  source: {
    id: string;
    type: string;
    name: string;
  };
  type: FileType;
  schedule: {
    frequency: Frequency;
    time: string;
    days: number[];
    calculateDays?: MonthPeriods[];
  };
}

export interface CreateEditTemplateType {
  name: string;
  createDate: string;
  author: string;
  contacts: { value: string }[];
  source: {
    id: string;
    type: string;
  };
  type: FileType;
  schedule: {
    frequency: string;
    time: string;
    days: (number | MonthPeriods)[];
    calculateDays?: MonthPeriods[];
  };
}

export interface SendDashboardParams {
  id: string;
  dataType: DataType;
  fileType: FileType;
  contacts: ContactParamType[];
}

export interface Rectangle {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}
