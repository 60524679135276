import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './PrintModal.module.less';
import { Button, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { emailPattern } from '../../../../../constants/regex';
import { debounceCall } from '../../../../../utils/debounceCall';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmails, selectContacts } from '../../../dashboardSlice';
import { ContactTypes } from '../../../types/dashboard';

interface IEmailInputProps {
  value: string[];
  setValue: (value: string[]) => void;
  disabled?: boolean;
  options?: {
    label: string;
    value: string;
  };
}

const EmailSelect = ({ value, setValue, disabled }: IEmailInputProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const options = useSelector(selectContacts);
  const formattedOptions = options.map(el => ({ ...el, label: el.value }));

  const handleDelete = (el: string) => {
    const deleted = value.filter(item => el !== item);
    setValue(deleted);
  };

  const handleChange = (elements: string[]) => {
    const filterInvalid = elements.filter(el => emailPattern.test(el));
    setValue(filterInvalid);
  };

  const placeholder = intl.formatMessage({
    id: 'app.inputEmails',
    defaultMessage: 'Введите адреса электронной почты',
  });

  const fetchOptions = (params: string) =>
    dispatch(fetchEmails(params, ContactTypes.EMAIL));

  const timeOutCall = debounceCall(fetchOptions, 500);

  const onSearch = (inputValue: string) => timeOutCall(inputValue);

  return (
    <div className={styles.emailContainer}>
      <div className={styles.inputContainer}>
        <span className={styles.label}>
          <FormattedMessage id="Common.Emails" defaultMessage="Адреса" />
        </span>
        <Select
          mode="tags"
          disabled={disabled}
          onSearch={onSearch}
          allowClear
          options={formattedOptions}
          tokenSeparators={[',', ' ']}
          placeholder={placeholder}
          className={styles.select}
          onChange={handleChange}
          value={value}
        />
      </div>
      <div className={styles.emailList}>
        {value.map(el => (
          <Button size="small" className={styles.emailItems} key={el}>
            {el}
            <CloseOutlined onClick={() => handleDelete(el)} size={6} />
          </Button>
        ))}
      </div>
    </div>
  );
};

export default EmailSelect;
