import React from 'react';
import _ from 'lodash';
import {
  Area,
  Bar,
  Bullet,
  Column,
  Funnel,
  Histogram,
  Line,
  Liquid,
  Progress,
  Radar,
  RingProgress,
  Rose,
  Scatter,
  TinyArea,
  TinyColumn,
  TinyLine,
  WordCloud,
  Sunburst,
  DualAxes,
  Stock,
  Gauge,
  OrganizationTreeGraph,
  DagreGraph,
  IndentedTree,
  DagreFundFlowGraph,
} from '@ant-design/charts';
import { ChartWidgetConfig } from '../../types/chart';
import PieChartWidget from './components/PieChartWidget/';
import { PieChartWidgetConfig } from './components/PieChartWidget/types';
import { BarConfig } from '@ant-design/charts/es/bar';
import { BulletConfig } from '@ant-design/charts/es/bullet';
import { ColumnConfig } from '@ant-design/charts/es/column';
import { FunnelConfig } from '@ant-design/charts/es/funnel';
import { HistogramConfig } from '@ant-design/charts/es/histogram';
import { ProgressConfig } from '@ant-design/charts/es/progress';
import { LiquidConfig } from '@ant-design/charts/es/liquid';
import { RadarConfig } from '@ant-design/charts/es/radar';
import { RingProgressConfig } from '@ant-design/charts/es/ringProgress';
import { RoseConfig } from '@ant-design/charts/es/rose';
import { ScatterConfig } from '@ant-design/charts/es/scatter';
import { TinyAreaConfig } from '@ant-design/charts/es/tinyArea';
import { TinyColumnConfig } from '@ant-design/charts/es/tinyColumn';
import { TinyLineConfig } from '@ant-design/charts/es/tinyLine';
import { WordCloudConfig } from '@ant-design/charts/es/wordCloud';
import { SunburstConfig } from '@ant-design/charts/es/sunburst';
import { DualAxesConfig } from '@ant-design/charts/es/dualAxes';
import { StockConfig } from '@ant-design/charts/es/stock';
import { GaugeConfig } from '@ant-design/charts/es/gauge';
import { AreaConfig } from '@ant-design/charts/es/area';
import { LineConfig } from '@ant-design/charts/es/line';

const defaultChartType = 'Line';

interface ChartWidgetProps {
  data: any[];
  config: ChartWidgetConfig;
}

const ChartWidget: React.FC<ChartWidgetProps> = ({ data = [], config }) => {
  const chartType = config.chartType || defaultChartType;

  const legendConfig =
    config.config && 'legend' in config.config ? config.config.legend : {};

  const initialConfig = {
    legend: {
      maxWidthRatio: 1,
      ...legendConfig,
    },
    ...config?.config,
  };

  const resultConfig = {
    ...initialConfig,
    data,
  };

  const percentResultConfig = { ...initialConfig, percent: data[0]?.percent };

  switch (chartType) {
    case 'Area':
      return <Area {...(resultConfig as AreaConfig)} />;
    case 'Bar':
      // if data is empty and isGroup=true there is an error 'dodge is not support linear attribute, please use category attribute!`
      if (_.isEmpty(resultConfig.data)) return null;

      return <Bar {...(resultConfig as BarConfig)} />;
    case 'Bullet':
      return <Bullet {...(resultConfig as BulletConfig)} />;
    case 'Column':
      // if data is empty and isGroup=true there is an error 'dodge is not support linear attribute, please use category attribute!`
      if (_.isEmpty(resultConfig.data)) return null;
      return <Column {...(resultConfig as ColumnConfig)} />;
    case 'Funnel':
      return <Funnel {...(resultConfig as FunnelConfig)} />;
    case 'Histogram':
      return <Histogram {...(resultConfig as HistogramConfig)} />;
    case 'Liquid':
      return (
        <Liquid
          {...(percentResultConfig as LiquidConfig)}
          key={percentResultConfig.percent}
        />
      );
    case 'Pie':
      return (
        <PieChartWidget
          data={data}
          config={config.config as PieChartWidgetConfig}
        />
      );
    case 'Progress':
      return <Progress {...(resultConfig as ProgressConfig)} />;
    case 'Radar':
      return <Radar {...(resultConfig as RadarConfig)} />;
    case 'RingProgress':
      return (
        <RingProgress
          {...(percentResultConfig as RingProgressConfig)}
          key={percentResultConfig.percent}
        />
      );
    case 'Rose':
      return <Rose {...(resultConfig as RoseConfig)} />;
    case 'Scatter':
      return <Scatter {...(resultConfig as ScatterConfig)} />;
    case 'TinyArea':
      return <TinyArea {...(resultConfig as TinyAreaConfig)} />;
    case 'TinyColumn':
      return <TinyColumn {...(resultConfig as TinyColumnConfig)} />;
    case 'TinyLine':
      return <TinyLine {...(resultConfig as TinyLineConfig)} />;
    case 'WordCloud':
      return <WordCloud {...(resultConfig as WordCloudConfig)} />;
    case 'Sunburst':
      return <Sunburst {...(resultConfig as SunburstConfig)} />;
    case 'DualAxes':
      return <DualAxes {...(resultConfig as DualAxesConfig)} />;
    case 'Stock':
      return <Stock {...(resultConfig as StockConfig)} />;
    case 'Gauge':
      return (
        <Gauge
          {...(percentResultConfig as GaugeConfig)}
          key={percentResultConfig.percent}
        />
      );
    case 'OrganizationTreeGraph':
      return <OrganizationTreeGraph {...resultConfig} />;
    case 'DagreGraph':
      return <DagreGraph {...resultConfig} />;
    case 'IndentedTree':
      return <IndentedTree {...resultConfig} />;
    case 'DagreFundFlowGraph':
      return <DagreFundFlowGraph {...resultConfig} />;
    default:
      return <Line {...(resultConfig as LineConfig)} />;
  }
};

export default ChartWidget;
