import React, { useEffect, useState } from 'react';
import AddGroupDrawer from './AddGroup/AddGroupDrawer';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteGroup,
  fetchGroupList,
  fetchUserList,
  selectGroupList,
} from '../../settingsSlice';
import { getColumns } from './columns';
import styles from '../SettingsUsers/styles.module.less';
import { isEven } from '../../../../utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { addGroupLabel } from '../SettingsUsers/AddUser/helper';
import { Group, GroupListItem } from '../../types/group';
import { selectDashboardList } from '../../../dashboard/dashboardSlice';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { hasAccess } from '../../../../utils/rights';
import { Permissions } from '../../types/user';

const SettingsGroups: React.FC = () => {
  const dispatch = useDispatch();
  const userGrants = useSelector(selectCurrentUserGrants);
  const hasAddGroupRights = hasAccess([Permissions.GROUP_EDIT], userGrants);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isGroupEditing, setIsGroupEditing] = useState<boolean>(false);
  const [openedGroup, setOpenedGroup] = useState<GroupListItem | null>(null);

  const allDashboards = useSelector(selectDashboardList);

  useEffect(() => {
    dispatch(fetchUserList({ size: 0, page: 0 }));
    dispatch(
      fetchGroupList({
        size: 0,
        page: 0,
        sort: {
          field: 'name',
          type: 'ASC',
        },
      }),
    );
  }, [dispatch]);
  const groupList = useSelector(selectGroupList);

  const onAddClick = () => {
    setShowDrawer(!showDrawer);
    setOpenedGroup(null);
    setIsGroupEditing(false);
  };

  const handleDeleteClick = (groupId: string) => {
    dispatch(
      deleteGroup(groupId, () => {
        dispatch(
          fetchGroupList({
            size: 0,
            page: 0,
            sort: {
              field: 'name',
              type: 'ASC',
            },
          }),
        );
      }),
    );
  };

  const getRowClassName = (_: Group, index: number): string => {
    if (!isEven(index)) {
      return styles.oddTableRow;
    }

    return styles.evenRow;
  };

  const onRow = (group: Group) => {
    return {
      onClick: () => {
        setShowDrawer(true);
        setIsGroupEditing(true);
        setOpenedGroup(group);
      },
    };
  };

  return (
    <>
      <div>
        {hasAddGroupRights && (
          <Button
            type="text"
            size="small"
            className={styles.addButton}
            onClick={onAddClick}
            icon={<PlusCircleOutlined />}
          >
            <span>{addGroupLabel}</span>
          </Button>
        )}
        <AddGroupDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          isGroupEditing={isGroupEditing}
          openedGroup={openedGroup}
          dashBoardsListForSelector={allDashboards}
        />
      </div>
      <div>
        <Table<Group>
          rowClassName={getRowClassName}
          dataSource={groupList}
          columns={getColumns(handleDeleteClick, userGrants)}
          onRow={onRow}
        />
      </div>
    </>
  );
};

export default SettingsGroups;
