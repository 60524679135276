import React, { CSSProperties, ErrorInfo, ReactNode } from 'react';
import styles from './ErrorBoundary.module.less';
import { errorMessages } from "./helper";

interface ErrorBoundaryProps {
  children: ReactNode;
  errorMessage?: string;
  setErrorMessage?: (message: string) => void;
  style?: CSSProperties;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
    this.props.setErrorMessage?.(error?.message || '');
  }

  render() {
    const { error } = this.state;
    const { errorMessage, style } = this.props;

    if (error || errorMessage) {
      const errorText = error?.message || errorMessage;

      // Проверка существования ключа в объекте errorMessages
      const getDisplayMessage = () => {
        if ( errorText && errorMessages[errorText as keyof typeof errorMessages]){
          return errorMessages[errorText as keyof typeof errorMessages]
        }
        return errorText
      }

      return (
          <div style={style} className={styles.container} title={errorText}>
            {getDisplayMessage()}
          </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;