import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './settingsData.module.less';
import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import cn from 'classnames';

const tableNameLabel = (
  <FormattedMessage id="settings.TableName" defaultMessage="Название таблицы" />
);
const actionsLabel = (
  <FormattedMessage id="settings.Actions" defaultMessage="Действия" />
);
const uploadData = (
  <FormattedMessage id="settings.LoadData" defaultMessage="Загрузить данные" />
);

export const getColumns = (
  onUpload: (id: string, file: RcFile) => void,
  uploadingId: string,
) => [
  {
    title: tableNameLabel,
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: actionsLabel,
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => {
      const isUploading = id === uploadingId;
      const iconStyles = cn(styles.upload, {
        [styles.uploadActive]: !isUploading,
      });
      const onUploadClick = (file: RcFile) => onUpload(id, file);
      return (
        <Upload
          disabled={isUploading}
          beforeUpload={onUploadClick}
          showUploadList={false}
        >
          <div className={iconStyles}>
            {isUploading ? <LoadingOutlined /> : <DownloadOutlined />}
            {uploadData}
          </div>
        </Upload>
      );
    },
  },
];
