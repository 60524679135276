import React, { useEffect } from 'react';
import {
  Frequency,
  MonthPeriods,
  TemplateType,
} from '../../../types/dashboard';
import styles from './PrintModal.module.less';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import {
  frequencyOptions,
  lastDayLabel,
  typeOptions,
  weekDaysOptions,
} from './helpers';
import { message } from 'antd';
import {
  dateTimeSeparatedFormat,
  formatWithTimezone,
  fullTimeFormat,
  hoursMinutesFormat,
  serverDateFullTimeFormat,
} from '../../../../../utils/dateFormats';

interface TemplateProps {
  template: TemplateType | null;
}

const TemplateView = ({ template }: TemplateProps) => {
  const intl = useIntl();
  const emailsCopiedMessage = intl.formatMessage({
    id: 'Common.EmailsCopied',
    defaultMessage: 'Адреса скопированы',
  });

  const copiedMessage = intl.formatMessage({
    id: 'Common.Copied',
    defaultMessage: 'Скопировано',
  });

  const frequencyOption = frequencyOptions.find(
    el => el.value === template?.schedule.frequency,
  );

  const typeOption = typeOptions.find(el => el.value === template?.type);

  const emails = template?.contacts.map(el => el.value).join(',') || '';

  const hasLastDayOption = template?.schedule?.calculateDays?.includes(
    MonthPeriods.LAST_DAY,
  );

  const weekDays = template?.schedule.days.map(
    el => weekDaysOptions.find(day => day.value === el)?.label,
  );

  const frequencyDays =
    template?.schedule.frequency === Frequency.WEEK
      ? weekDays?.join(',')
      : [
          ...(template?.schedule.days || []),
          ...(hasLastDayOption ? [lastDayLabel] : []),
        ].join(',');

  const onCopyEmails = (e: ClipboardEvent) => {
    if (!window.getSelection()?.toString()) {
      e.preventDefault();
      e.clipboardData?.setData('text/plain', emails);
      message.success(emailsCopiedMessage);
    } else {
      message.success(copiedMessage);
    }
  };

  useEffect(() => {
    document.addEventListener('copy', onCopyEmails);
    return () => document.removeEventListener('copy', onCopyEmails);
  }, [emails]);

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id="widgetForm.Name" defaultMessage="Название" />:
          </span>
          <span className={styles.info}>{template?.name}</span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id="Common.Author" defaultMessage="Автор" />:
          </span>
          <span className={styles.info}>{template?.author}</span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.CreationDate"
              defaultMessage="Дата создания"
            />
            :
          </span>
          <span className={styles.info}>
            {formatWithTimezone(
              template?.createDate,
              dateTimeSeparatedFormat,
              serverDateFullTimeFormat,
            )}
          </span>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id="Common.Emails" defaultMessage="Адреса" />:
          </span>
          <span className={styles.info}>{emails}</span>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.SendingFormat"
              defaultMessage="Формат отправления"
            />
            :
          </span>
          <span className={styles.info}>{typeOption?.label}</span>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.SendingFrequency"
              defaultMessage="Периодичность отправления"
            />
            :
          </span>
          <span className={styles.info}>{frequencyOption?.label}</span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.SendingTime"
              defaultMessage="Время отправления"
            />
            :
          </span>
          <span className={styles.info}>
            {template?.schedule.time &&
              moment(template?.schedule.time, fullTimeFormat).format(
                hoursMinutesFormat,
              )}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.SendingDate"
              defaultMessage="Дата отправления"
            />
            :
          </span>
          <span className={styles.info}>
            {template?.schedule.frequency === Frequency.DAY ? (
              <FormattedMessage
                id="Common.everyDay"
                defaultMessage="Каждый день"
              />
            ) : (
              frequencyDays
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TemplateView;
