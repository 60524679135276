import { host, LocaleEnum } from '../config';

export const initialGlobalConfig = {
  backend: host,
  receivedConfig: {
    localisation: [LocaleEnum.RU],
    passwordMinLength: 0,
    auth: {
      url: '',
      realm: '',
      clientId: '',
      scopes: '',
    },
  },
};
