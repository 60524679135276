import React, { useEffect, useRef, useState } from 'react';
import * as WebDataRocksReact from '@webdatarocks/react-webdatarocks';
import styles from './PivotTableWidget.module.less';
import ruLocale from '../../../../i18n/ruPivotTableLocale.json';
import { toolbarTabIds } from './helper';
import { Report, Slice, Sorting, ToolbarItem } from '../../types/pivotTable';

interface IPivotTableWidgetProps {
  // TODO исправить типы после тестирования библиотеки
  data: any;
  config: any;
  isEditing: boolean;
  id: string;
  onChange: (value: string) => void;
}

const PivotTableWidget = ({
  data,
  config,
  isEditing,
  onChange,
  id,
}: IPivotTableWidgetProps) => {
  const sortingStorageKey = `${id}-sorting`;
  const storageSorting = sessionStorage.getItem(sortingStorageKey);
  const [sorting, setSorting] = useState<Sorting[]>(
    storageSorting ? JSON.parse(storageSorting) : storageSorting,
  );
  const tableRef = useRef<WebDataRocksReact.Pivot>(null);
  const report = {
    ...config,
    slice: {
      ...config.slice,
      sorting,
    },
    dataSource: {
      data,
    },
  };

  const globalConfig = {
    localization: ruLocale,
    options: {
      configuratorButton: false,
      configuratorActive: false,
      grid: {
        showFilter: isEditing,
        showHeaders: false,
      },
    },
  };

  useEffect(() => {
    tableRef.current?.webdatarocks.setReport(report);
  });

  const customizeToolbar = (toolbar: { getTabs: () => ToolbarItem[] }) => {
    const tabs = toolbar?.getTabs();
    if (toolbar) {
      toolbar.getTabs = () => {
        return tabs?.filter(el => toolbarTabIds.includes(el.id));
      };
    }
  };

  const handleSortingSave = (sort?: Sorting[]) => {
    if (sort) {
      sessionStorage.setItem(sortingStorageKey, JSON.stringify(sort));
      setSorting(sort);
    }
  };

  const handleReportChange = () => {
    const { dataSource, ...other } =
      tableRef.current?.webdatarocks.getReport() as Report;
    const { sorting: sort, ...otherSlice } = other.slice as Slice;

    const value = JSON.stringify(
      { ...config, ...other, slice: { ...otherSlice } },
      null,
      2,
    );
    handleSortingSave(sort);
    if (isEditing) {
      onChange(value);
    }
  };

  // Монтирование при изменении режима на редактирование(тулбар не показывается если полностью не перерендерить компонент)
  const forceUpdateKey = String(isEditing);

  return (
    <div key={forceUpdateKey} className={styles.pivotTableStyles}>
      <WebDataRocksReact.Pivot
        global={globalConfig}
        beforetoolbarcreated={customizeToolbar}
        toolbar={isEditing}
        reportchange={handleReportChange}
        ref={tableRef}
        width="100%"
        height="100%"
        report={report}
      />
    </div>
  );
};

export default PivotTableWidget;
