import React from 'react';
import { Button } from 'antd';
import { DisplayMode } from '../../features/dashboard/types/displayMode';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

interface Props {
  value: DisplayMode;
  hidden?: boolean;
  onChange: () => any;
}

const Mode: React.FC<Props> = ({ value, onChange, hidden = false }) => {
  const intl = useIntl();

  if (hidden) return null;

  const icon = value === DisplayMode.VIEW ? <EditOutlined /> : <EyeOutlined />;

  const editTitle = intl.formatMessage({
    id: 'dashboard.Edit',
    defaultMessage: 'Редактировать дашборд',
  });

  const viewTitle = intl.formatMessage({
    id: 'dashboard.View',
    defaultMessage: 'Режим просмотра',
  });

  const title = value === DisplayMode.VIEW ? editTitle : viewTitle;

  return <Button title={title} shape="circle" icon={icon} onClick={onChange} />;
};

export default Mode;
