import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Radio, RadioChangeEvent } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './PrintModal.module.less';
import cn from 'classnames';
import TemplateModal from './TemplateModal';
import EmailSelect from './EmailSelect';
import { tabs, typeOptions } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTemplate,
  downloadDashboardWidget,
  fetchTemplates,
  resetTemplate,
  selectLoadingSendDashboardWidget,
  selectTemplates,
  sendDashboardWidget,
} from '../../../dashboardSlice';
import { DataType, FileType } from '../../../types/dashboard';
import TemplateItem from './TemplateItem';
import { WidgetTypes } from '../../../../../components/Widget/types/common';

interface IPrintModalProps {
  isOpen: boolean;
  onClose: () => void;
  selected: {
    name: string;
    id: string;
    dataType: DataType;
    widgetType?: WidgetTypes;
  };
}

const PrintModal = ({ isOpen, onClose, selected }: IPrintModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const templates = useSelector(selectTemplates);
  const sendingLoading = useSelector(selectLoadingSendDashboardWidget);

  const dashLabel = intl.formatMessage({
    id: 'dashboard.DashboardAction',
    defaultMessage: 'Действия с дашбордом',
  });
  const widgetLabel = intl.formatMessage({
    id: 'widget.WidgetAction',
    defaultMessage: 'Действия с виджетом',
  });
  const dataSent = intl.formatMessage({
    id: 'common.DataSent',
    defaultMessage: 'Данные отправлены',
  });
  const preparingToDownloadTitle = intl.formatMessage({
    id: 'common.PreparingToDownload',
    defaultMessage: 'Подготовка к скачиванию',
  });

  const dataType = selected.widgetType ? DataType.Widget : DataType.Dashboard;

  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [isTemplateView, setIsTemplateView] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const createTemplate = () => {
    setTemplateId('');
    setIsTemplateView(false);
    dispatch(resetTemplate());
    setOpenTemplateModal(true);
  };

  const onTemplateModalClose = () => {
    setTemplateId('');
    setOpenTemplateModal(false);
    setIsTemplateView(false);
  };

  const handleTemplateClick = (templateId: string, isView: boolean = false) => {
    setTemplateId(templateId);
    setOpenTemplateModal(true);
    setIsTemplateView(isView);
  };

  const [tab, setTab] = useState(tabs[0].id);
  const handleChangeTab = (selectedTab: string) => {
    onTemplateModalClose();
    setTab(selectedTab);
  };

  const handleDownload = (fileType: FileType) => {
    const hide = message.loading(preparingToDownloadTitle, 0);
    setIsDownloading(true);
    const requestEnd = () => {
      setIsDownloading(false);
      hide();
    };
    dispatch(
      downloadDashboardWidget(
        selected.id,
          selected.name,
          selected.dataType,
        fileType,
        requestEnd,
        requestEnd,
      ),
    );
  };

  const onModalClose = () => {
    onClose();
    setTemplateId('');
    setOpenTemplateModal(false);
  };

  const [emails, setEmails] = useState<string[]>([]);
  const [fileType, setFileType] = useState<FileType>(FileType.PNG);
  const handleChangeFileType = (e: RadioChangeEvent) =>
    setFileType(e.target.value);

  const sendFile = () => {
    const formatEmails = emails.map(el => ({ value: el }));
    dispatch(
      sendDashboardWidget(
        {
          id: selected.id,
          dataType,
          fileType,
          contacts: formatEmails,
        },
        () => message.success(dataSent),
      ),
    );
  };

  const onTemplateDeleteClick = (id: string) => {
    dispatch(
      deleteTemplate(id, () => {
        dispatch(fetchTemplates(selected.id));
        onTemplateModalClose();
      }),
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchTemplates(selected.id));
    }
  }, [isOpen]);

  return (
    <Drawer
      title={
        <span className={styles.mainTitle}>
          {selected.widgetType ? widgetLabel : dashLabel} "{selected.name}"
        </span>
      }
      placement="left"
      width={520}
      open={isOpen}
      onClose={onModalClose}
      closeIcon={<ArrowLeftOutlined />}
      bodyStyle={{ paddingBottom: 80, paddingRight: 0 }}
      footer={
        tab === tabs[0].id && (
          <Button
            loading={sendingLoading}
            disabled={!emails.length}
            onClick={sendFile}
            size="large"
            htmlType="submit"
            type="primary"
          >
            <FormattedMessage id="Common.send" defaultMessage="Отправить" />
          </Button>
        )
      }
    >
      <div className={styles.container}>
        <div className={styles.printContainer}>
          <div className={styles.title}>
            <FormattedMessage
              id="Common.SaveToTheDevice"
              defaultMessage="Сохранить на устройство"
            />
          </div>
          <div className={styles.buttonsContainer}>
            <button
              disabled={isDownloading}
              onClick={() => handleDownload(FileType.PNG)}
              className={styles.btn}
            >
              <FormattedMessage id="Common.Download" defaultMessage="Скачать" />
              .png
            </button>
            <button
              disabled={isDownloading}
              onClick={() => handleDownload(FileType.PDF)}
              className={styles.btn}
            >
              <FormattedMessage id="Common.Download" defaultMessage="Скачать" />
              .pdf
            </button>
            <button
              disabled={isDownloading}
              onClick={() => handleDownload(FileType.XLSX)}
              className={styles.btn}
            >
              <FormattedMessage id="Common.Download" defaultMessage="Скачать" />
              .xlsx
            </button>
          </div>
          <div className={styles.divider} />
          <div className={styles.title}>
            <FormattedMessage
              id="Common.SendByEmail"
              defaultMessage="Отправить по электронной почте"
            />
          </div>
          <div className={styles.buttonsContainer}>
            {tabs.map(({ id, name }) => (
              <button
                key={id}
                onClick={() => handleChangeTab(id)}
                className={cn(styles.tabButtons, styles.btn, {
                  [styles.activeTab]: tab === id,
                })}
              >
                {name}
              </button>
            ))}
          </div>
          {tab === tabs[0].id && (
            <>
              <div className={styles.divider} />
              <div className={styles.subTitle}>
                <FormattedMessage
                  id="Common.SendingFormat"
                  defaultMessage="Формат отправления"
                />
              </div>
              <Radio.Group onChange={handleChangeFileType} value={fileType}>
                {typeOptions.map(({ value, label }) => (
                  <Radio key={value} value={value}>
                    {label}
                  </Radio>
                ))}
              </Radio.Group>
              <div className={styles.section}>
                <EmailSelect value={emails} setValue={setEmails} />
              </div>
            </>
          )}
          {tabs[1].id === tab && (
            <div className={styles.templateContainer}>
              <Button
                size="large"
                className={styles.addTemplateBtn}
                onClick={createTemplate}
                icon={<PlusCircleOutlined />}
              >
                <FormattedMessage
                  id="SendingTemplate.Add"
                  defaultMessage="Добавить шаблон рассылки"
                />
              </Button>
              {templates.map(el => (
                <TemplateItem
                  key={el.id}
                  isActive={el.id === templateId && isTemplateView}
                  isEditActive={el.id === templateId && !isTemplateView}
                  onDelete={() => onTemplateDeleteClick(el.id)}
                  onItemClick={() => handleTemplateClick(el.id, true)}
                  onEdit={() => handleTemplateClick(el.id)}
                  info={el}
                />
              ))}
            </div>
          )}
        </div>
        {openTemplateModal && (
          <TemplateModal
            selected={selected}
            isView={isTemplateView}
            templateId={templateId}
            onClose={onTemplateModalClose}
          />
        )}
      </div>
    </Drawer>
  );
};

export default PrintModal;
