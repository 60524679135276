import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWidgetError,
  selectCurrentDashboard,
  selectWidgetData,
  selectWidgetErrors,
  setWidgetError,
  updateWidgetGrid,
} from '../../features/dashboard/dashboardSlice';
import { RootState } from '../../store';
import WidgetBody from './components/WidgetBody';
import cn from 'classnames';
import styles from './Widget.module.less';
import { WidgetConfig } from './types/common';
import { WidgetGrid } from '../../features/dashboard/types/dashboard';

interface Props {
  widgetGrid: WidgetGrid;
  isEditing: boolean;
  onChangeParameter: (widgetId: string, value: any) => void;
  widgetAccessoryRight: ReactNode;
}

const Widget: React.FC<Props> = ({
  widgetGrid,
  isEditing,
  onChangeParameter,
  widgetAccessoryRight,
}) => {
  const widget = widgetGrid.widget;
  const dashboard = useSelector(selectCurrentDashboard);
  const widgetId = widget.id || '';
  const dispatch = useDispatch();
  const widgetErrors = useSelector(selectWidgetErrors);
  const widgetError = widgetErrors.find(el => el.id === widgetId);
  const [config, setConfig] = useState<WidgetConfig>({});
  const data = useSelector((state: RootState) =>
    selectWidgetData(state, widgetId),
  );

  const parseConfig = () => {
    try {
      setConfig(JSON.parse(widget.component));
      dispatch(deleteWidgetError(widgetId));
    } catch (_) {
      dispatch(setWidgetError({ id: widgetId, message: 'Cannot parse JSON' }));
    }
  };

  const handleChangeParameter = (value: any) => {
    if (widgetId) {
      onChangeParameter(widgetId, value);
    }
  };

  useEffect(() => {
    parseConfig();
  }, [widget.component]);

  const containerStyles = cn(styles.bodyWrap, {
    [styles.bordered]: !config.flat,
  });

  const handleWidgetComponentChange = (component: string) => {
    if (dashboard) {
      dispatch(
        updateWidgetGrid(dashboard.id, {
          ...widgetGrid,
          widget: { ...widgetGrid.widget, component },
        }),
      );
    }
  };

  return (
    <div className={containerStyles}>
      <WidgetBody
        id={widgetId}
        isEditing={isEditing}
        accessoryRight={widgetAccessoryRight}
        widgetType={widget.type}
        data={data}
        handleWidgetComponentChange={handleWidgetComponentChange}
        errorMessage={widgetError?.message}
        config={config}
        onChange={handleChangeParameter}
        parameter={widget.parameter}
      />
    </div>
  );
};

export default Widget;
