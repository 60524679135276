import { Dashboard, Rectangle, WidgetGrid } from '../../types/dashboard';
import { Layout } from 'react-grid-layout';
import _ from 'lodash';
import { WidgetTypes } from '../../../../components/Widget/types/common';

const minMaxSizes: Partial<Record<WidgetTypes, { [key: string]: number }>> = {
  [WidgetTypes.CHART]: {
    minW: 3,
  },
};

/**
 * Getting widget position parameters in a format compatible with react-grid-layout
 *
 * @param dashboard
 */
export const getLayout = (dashboard: Dashboard): Layout[] => {
  return dashboard.widgetGrids.map(grid => {
    if (!grid.id) {
      throw Error('The widget grid needs id');
    }

    return {
      i: grid.id,
      ...(minMaxSizes[grid.widget.type] || {}),
      ...grid.position,
    };
  });
};

/**
 * Applying new widget positions to a dashboard
 *
 * @param dashboard
 * @param layout
 */
export const updateDashboardGridPositions = (
  dashboard: Dashboard,
  layout: Layout[],
): Dashboard => {
  const newWidgetGrids: WidgetGrid[] = dashboard.widgetGrids.map(grid => {
    const position = layout.find(l => l.i === grid.id);

    return {
      ...grid,
      position: position ? _.omit(position, 'i') : grid.position,
    };
  });

  return { ...dashboard, widgetGrids: newWidgetGrids };
};

export const isActiveWidgetGrid = (
  widgetGrid: WidgetGrid,
  activeWidgetGrid: WidgetGrid | null,
): boolean => {
  if (!activeWidgetGrid) {
    return false;
  }

  return widgetGrid.id === activeWidgetGrid.id;
};

export const checkRectanglesOverlap = (
  rectangles: Rectangle[],
  threshold: number,
) => {
  let hasOverLap = false;
  for (let i = 0; i < rectangles.length; i++) {
    for (let j = i + 1; j < rectangles.length; j++) {
      const intersection = getIntersection(rectangles[i], rectangles[j]);
      if (intersection) {
        hasOverLap =
          intersection.width >= threshold && intersection.height >= threshold;
      }
    }
  }
  return hasOverLap;
};

export const getIntersection = (rect1: Rectangle, rect2: Rectangle) => {
  // Найти границы пересечения
  const left = Math.max(rect1?.left, rect2?.left);
  const right = Math.min(rect1?.right, rect2?.right);
  const top = Math.max(rect1?.top, rect2?.top);
  const bottom = Math.min(rect1?.bottom, rect2?.bottom);

  // Проверить, есть ли пересечение
  if (left < right && top < bottom) {
    // Вычислить размеры пересекающейся области
    const width = right - left;
    const height = bottom - top;
    return { width, height };
  } else {
    // Нет пересечения
    return null;
  }
};
