export interface WidgetConfig {
  title?: string;
  /**
   * Widget refresh interval in milliseconds
   */
  interval?: number;
  padding?: number;
  /**
   * Show widget without border and shadow
   */
  flat?: boolean;
  /**
   * Table widget type
   */
  isPivot?: boolean;
}

export interface InputWidgetConfig extends WidgetConfig {
  label?: string;
}

export enum WidgetTypes {
  CHART = 'CHART',
  TABLE = 'TABLE',
  TIME = 'TIME',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  STATISTIC = 'STATISTIC',
}
