import React from 'react';
import styles from './WidgetsPanel.module.scss';
import { Button, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Mode from './Mode';
import { DisplayMode } from '../../features/dashboard/types/displayMode';
import WidgetButton from '../WidgetButton';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../features/user/userSlice';
import { Permissions } from '../../features/settings/types/user';
import { hasAccess } from '../../utils/rights';
import DotsIcon from '../../assets/icons/DotsIcon';
import { widgetOptions } from '../Widget/helper';

interface Props {
  onSave: () => void;
  onReload: () => void;
  displayMode: DisplayMode;
  onChangeDisplayMode: (mode: DisplayMode) => void;
  loading: boolean;
  changed: boolean;
  onPrintModalOpen: () => void;
}

const WidgetsPanel: React.FC<Props> = ({
  onSave,
  onReload,
  displayMode,
  onChangeDisplayMode,
  loading,
  changed,
  onPrintModalOpen,
}) => {
  const intl = useIntl();
  const userGrants = useSelector(selectCurrentUserGrants);
  const isEditing = displayMode === DisplayMode.EDIT;

  const hasAccessToEdit = hasAccess([Permissions.DASHBOARD_EDIT], userGrants);

  const handleChangesSave = () => {
    if (changed) {
      onSave();
    }
  };

  const toggleMode = () => {
    if (displayMode === DisplayMode.EDIT) {
      onChangeDisplayMode(DisplayMode.VIEW);
      handleChangesSave();
    } else {
      onChangeDisplayMode(DisplayMode.EDIT);
    }
  };

  return (
    <div className={styles.container}>
      {isEditing &&
        widgetOptions.map(button => (
          <WidgetButton
            key={button.type}
            icon={button.icon}
            name={button.type}
            title={button.title}
          />
        ))}
      <div className={styles.splitter} />
      <Space>
        <Button
          shape="circle"
          icon={<ReloadOutlined />}
          title={intl.formatMessage({
            id: 'dashboard.Refresh',
            defaultMessage: 'Обновить',
          })}
          loading={loading}
          onClick={onReload}
        />
        {!isEditing && (
          <Button
            shape="circle"
            style={{ paddingTop: 4 }}
            icon={<DotsIcon width={18} height={18} />}
            onClick={onPrintModalOpen}
            title={intl.formatMessage({
              id: 'dashboard.DashboardAction',
              defaultMessage: 'Действия с дашбордом',
            })}
          />
        )}
        <Mode
          value={displayMode}
          onChange={toggleMode}
          hidden={!hasAccessToEdit}
        />
      </Space>
    </div>
  );
};

export default WidgetsPanel;
