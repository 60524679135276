import React, { useEffect } from 'react';
import styles from './Dashboard.module.less';
import DashSkeleton from '../../../../components/DashSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDashboard,
  fetchWidget,
  isDashboardLoading,
  reset,
  selectCurrentDashboard,
} from '../../dashboardSlice';
import { useParams } from 'react-router-dom';
import WidgetGridItem from '../../../../components/WidgetGridItem';
import { getLayout } from './helper';
import RGL, { WidthProvider } from 'react-grid-layout';
import tokenService from '../../../../utils/tokenService';
import Provider from '../../../../i18n/Provider';
import ErrorMessenger from '../../../../components/ErrorMessenger';
import { getLocale } from '../../../../i18n/i18nSlice';
import { useQuery } from '../../../../hooks/useQuery';

const GridLayout = WidthProvider(RGL);

const cbEmpty = () => {};

const PrintDashboard = () => {
  const { dataId, dataType } = useParams<{
    dataId: string;
    dataType: 'dashboard' | 'widget';
  }>();

  const query = useQuery();

  const token = query.get('token');

  const dispatch = useDispatch();
  const loading = useSelector(isDashboardLoading);
  const dashboard = useSelector(selectCurrentDashboard);
  const locale = useSelector(getLocale);
  const fetchData = dataType === 'dashboard' ? fetchDashboard : fetchWidget;

  useEffect(() => {
    tokenService.setAccessToken(token || '');
    dispatch(fetchData(dataId, true));

    return () => {
      dispatch(reset());
    };
  }, []);

  if (loading) {
    return <DashSkeleton />;
  }

  return (
    <Provider locale={locale}>
      <ErrorMessenger />
      <div className={styles.container}>
        {dashboard && (
          <GridLayout
            containerPadding={[0, 0]}
            className="layout"
            layout={getLayout(dashboard)}
            cols={120}
            verticalCompact={false}
            rowHeight={10}
            isResizable={false}
            isDraggable={false}
            isDroppable={false}
          >
            {dashboard?.widgetGrids.map(widgetGrid => {
              return (
                <div key={widgetGrid.id} className={styles.widgetGridItemWrap}>
                  <WidgetGridItem
                    key={widgetGrid.id}
                    widgetGrid={widgetGrid}
                    editable={false}
                    onSelect={cbEmpty}
                    active={false}
                    onEditBtnClick={cbEmpty}
                    onChangeWidgetParameter={cbEmpty}
                  />
                </div>
              );
            })}
          </GridLayout>
        )}
      </div>
    </Provider>
  );
};

export default PrintDashboard;
