import { userGroupNameLabel } from '../SettingsUsers/AddUser/helper';
import { sortAlphabetically } from '../../../../utils';
import styles from '../SettingsUsers/styles.module.less';
import { DeleteOutlined } from '@ant-design/icons';
import React, { MouseEvent } from 'react';
import { Group } from '../../types/group';
import { hasAccess } from '../../../../utils/rights';
import { Permissions } from '../../types/user';
import { intl } from '../../../../utils/intl';

const deleteGroupTitle = intl.formatMessage({
  id: 'Settings.delete',
  defaultMessage: 'Удалить группу',
});

export const getColumns = (
  onDeleteClick: (userId: string) => void,
  userGrants: Permissions[],
) => {
  const hasDeleteUserRights = hasAccess([Permissions.GROUP_EDIT], userGrants);
  return [
    {
      title: userGroupNameLabel,
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: any, b: any) => sortAlphabetically(a.name, b.name),
      },
      render: (text: string, group: Group) => {
        const onDeleteUser = (e: MouseEvent) => {
          e.stopPropagation();
          onDeleteClick(group.id);
        };

        return (
          <div className={styles.fullNameContainer}>
            {hasDeleteUserRights && (
              <span title={deleteGroupTitle} className={styles.deleteBtn}>
                <DeleteOutlined onClick={onDeleteUser} />
              </span>
            )}
            {text}
          </div>
        );
      },
    },
  ];
};
