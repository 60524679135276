import { WidgetGrid, WidgetPosition } from './dashboard';
import { Modify } from '../../../utils/tsHelpers';
import { WidgetTypes } from '../../../components/Widget/types/common';

export enum WidgetDatasourceType {
  /**
   * параметр для виджетов для работы с запросами БД
   */
  QUERY = 'QUERY',

  /**
   * Данные из очереди
   */
  QUEUE = 'QUEUE',

  /**
   * Статичные данные
   */
  STATIC = 'STATIC',

  UNKNOWN = 'UNKNOWN',
}

export interface WidgetDatasourceRule {
  value: string;
}

export interface Widget {
  id?: string;
  name: string;
  idName: string;
  component: string;
  widgetDatasourceRule: WidgetDatasourceRule;
  type: WidgetTypes;
  datasourceType: WidgetDatasourceType;
  parameter: any;
}

export interface CreateWidgetOptions {
  type: WidgetTypes;
}

export type NewWidgetType = Modify<
  Widget,
  {
    name: null;
    idName: null;
  }
>;

export type NewWidgetGridType = Modify<WidgetGrid, { widget: NewWidgetType }>;

export interface CreateWidgetGridOptions {
  widgetOptions: CreateWidgetOptions;
  position: WidgetPosition;
}
