import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.less';
import App from './components/App';
import { Provider } from 'react-redux';
import { store } from './store';
import API from './utils/API';
import { logout } from './features/auth/authSlice';
import KeycloakProvider from './features/auth/components/Keycloak/KeycloakProvider';
import ConfigProvider from './components/ConfigProvider/ConfigProvider';
import PrintDashboard from './features/dashboard/routes/Dashboard/PrintDashboard';

const UNAUTHORIZED = 401;

ReactDOM.render(
  // There is a problem with Button from ant-design in strict mode
  // https://github.com/ant-design/ant-design/issues/22493
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/print/:dataType/:dataId">
            <PrintDashboard />
          </Route>
          <Route path="/">
            <ConfigProvider>
              <KeycloakProvider>
                <App />
              </KeycloakProvider>
            </ConfigProvider>
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Intercept any unauthorized request.
// dispatch logout action accordingly
const { dispatch } = store; // direct access to redux store.
API.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      return Promise.reject(Error('Network error'));
    }
    const { status } = error.response;

    if (status === UNAUTHORIZED) {
      dispatch(logout());
    }
    return Promise.reject(error);
  },
);
