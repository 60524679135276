import React from 'react';
import styles from '../../Widget.module.less';

interface TextWidgetProps {
  data: [{ value?: string }];
  config: {
    title?: string;
  };
}

const TextWidget = ({ data, config }: TextWidgetProps) => {
  const value = (data && data[0]?.value) || '';
  return (
    <div className={styles.textWidgetStyles}>
      <div className={styles.textWidgetTitle}>{config?.title}</div>
      {value}
    </div>
  );
};

export default TextWidget;
