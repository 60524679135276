import _ from 'lodash';
import { WidgetTypes } from './types/common';
import {
  createTableWidgetColumn,
  TableWidgetColumn,
  TableWidgetData,
} from './types/table';
import React from 'react';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  FieldBinaryOutlined,
  FieldTimeOutlined,
  FireOutlined,
  FontSizeOutlined,
  LikeOutlined,
  LineChartOutlined,
  SelectOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { PlainObject } from '../../types/common';
import { intl } from '../../utils/intl';
import {
  CreateWidgetGridOptions,
  CreateWidgetOptions,
  NewWidgetGridType,
  NewWidgetType,
  WidgetDatasourceType,
} from '../../features/dashboard/types/widget';

export const getDefaultColumns = (
  data: TableWidgetData[],
): TableWidgetColumn[] => {
  if (_.isEmpty(data)) {
    return [];
  }
  return Object.keys(data[0]).map(item =>
    createTableWidgetColumn(item, item, item),
  );
};

export const statisticWidgetIconMap: PlainObject = {
  arrowUp: <ArrowUpOutlined />,
  arrowDown: <ArrowDownOutlined />,
  clockCircle: <ClockCircleOutlined />,
  like: <LikeOutlined />,
  fire: <FireOutlined />,
  calendar: <CalendarOutlined />,
};

export const renderStatistic = (containerWidth: number, text: string) => {
  const R = containerWidth / 2;
  const scale = Math.min(
    Math.sqrt(
      Math.abs(Math.pow(R, 2) / (Math.pow(10 / 2, 2) + Math.pow(10, 2))),
    ),
    1,
  );
  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
    scale < 1 ? 1 : 'inherit'
  };">${text}</div>`;
};

export const untitled = intl.formatMessage({
  id: 'widget.untitled',
  defaultMessage: 'Без названия',
});

export const widgetTitles = {
  [WidgetTypes.TEXT]: intl.formatMessage({
    id: 'widgetForm.Text',
    defaultMessage: 'Текст',
  }),
  [WidgetTypes.DATE]: intl.formatMessage({
    id: 'widgetForm.Date',
    defaultMessage: 'Дата',
  }),
  [WidgetTypes.DATETIME]: intl.formatMessage({
    id: 'widgetForm.DateTime',
    defaultMessage: 'Дата и время',
  }),
  [WidgetTypes.TIME]: intl.formatMessage({
    id: 'widgetForm.TIME',
    defaultMessage: 'Время',
  }),
  [WidgetTypes.TABLE]: intl.formatMessage({
    id: 'widgetForm.Table',
    defaultMessage: 'Таблица',
  }),
  [WidgetTypes.CHART]: intl.formatMessage({
    id: 'widgetForm.Chart',
    defaultMessage: 'Диаграмма',
  }),
  [WidgetTypes.SELECT]: intl.formatMessage({
    id: 'widgetForm.Select',
    defaultMessage: 'Селект',
  }),
  [WidgetTypes.STATISTIC]: intl.formatMessage({
    id: 'widgetForm.Statistic',
    defaultMessage: 'Статистика',
  }),
};

export const widgetOptions = [
  {
    type: WidgetTypes.TEXT,
    icon: <FontSizeOutlined />,
    title: widgetTitles[WidgetTypes.TEXT],
  },
  {
    type: WidgetTypes.DATE,
    icon: <CalendarOutlined />,
    title: widgetTitles[WidgetTypes.DATE],
  },
  {
    type: WidgetTypes.DATETIME,
    icon: <CalendarOutlined />,
    title: widgetTitles[WidgetTypes.DATETIME],
  },
  {
    type: WidgetTypes.TIME,
    icon: <FieldTimeOutlined />,
    title: widgetTitles[WidgetTypes.TIME],
  },
  {
    type: WidgetTypes.TABLE,
    icon: <TableOutlined />,
    title: widgetTitles[WidgetTypes.TABLE],
  },
  {
    type: WidgetTypes.CHART,
    icon: <LineChartOutlined />,
    title: widgetTitles[WidgetTypes.CHART],
  },
  {
    type: WidgetTypes.SELECT,
    icon: <SelectOutlined />,
    title: widgetTitles[WidgetTypes.SELECT],
  },
  {
    type: WidgetTypes.STATISTIC,
    icon: <FieldBinaryOutlined />,
    title: widgetTitles[WidgetTypes.STATISTIC],
  },
];

export const widgetInitialParams: Record<
  WidgetTypes,
  {
    component: { title: string };
    position: { h: number; w: number; minW?: number; minH?: number };
  }
> = {
  [WidgetTypes.TEXT]: {
    component: {
      title: widgetTitles[WidgetTypes.TEXT],
    },
    position: { h: 5, w: 20, minH: 3, minW: 9 },
  },
  [WidgetTypes.DATE]: {
    component: {
      title: widgetTitles[WidgetTypes.DATE],
    },
    position: { h: 5, w: 20, minW: 11, minH: 5 },
  },
  [WidgetTypes.DATETIME]: {
    component: {
      title: widgetTitles[WidgetTypes.DATETIME],
    },
    position: { h: 5, w: 20, minH: 5, minW: 20 },
  },
  [WidgetTypes.TIME]: {
    component: {
      title: widgetTitles[WidgetTypes.TIME],
    },
    position: { h: 5, w: 20, minW: 10, minH: 5 },
  },
  [WidgetTypes.TABLE]: {
    component: {
      title: widgetTitles[WidgetTypes.TABLE],
    },
    position: { h: 15, w: 30, minW: 10, minH: 10 },
  },
  [WidgetTypes.CHART]: {
    component: {
      title: widgetTitles[WidgetTypes.CHART],
    },
    position: { h: 15, w: 30, minH: 15, minW: 30 },
  },
  [WidgetTypes.SELECT]: {
    component: {
      title: widgetTitles[WidgetTypes.SELECT],
    },
    position: { h: 5, w: 20, minH: 5, minW: 20 },
  },
  [WidgetTypes.STATISTIC]: {
    component: {
      title: widgetTitles[WidgetTypes.STATISTIC],
    },
    position: { h: 5, w: 20, minH: 5, minW: 7 },
  },
};

export const createWidget = (options: CreateWidgetOptions): NewWidgetType => {
  return {
    component: JSON.stringify(widgetInitialParams[options.type].component),
    idName: null,
    name: null,
    type: options.type,
    widgetDatasourceRule: { value: '' },
    datasourceType: WidgetDatasourceType.STATIC,
    parameter: null,
  };
};

export const createWidgetGrid = (
  options: CreateWidgetGridOptions,
): NewWidgetGridType => {
  return {
    position: {
      ...options.position,
      ...widgetInitialParams[options.widgetOptions.type].position,
    },
    widget: createWidget(options.widgetOptions),
  };
};
