import React, { useEffect, useState } from 'react';
import styles from './PrintModal.module.less';
import EmailSelect from './EmailSelect';
import {
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  TimePicker,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  days,
  frequencyOptions,
  typeOptions,
  weekDaysOptions,
} from './helpers';
import {
  CreateEditTemplateType,
  DataType,
  Frequency,
  MonthPeriods,
  TemplateType,
} from '../../../types/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../user/userSlice';
import moment from 'moment';
import {
  createAndEditTemplate,
  fetchTemplates,
  selectLoadingEditTemplate,
} from '../../../dashboardSlice';
import {
  dateTimeSeparatedFormat,
  fullTimeFormat,
  hoursMinutesFormat,
} from '../../../../../utils/dateFormats';
import { WidgetTypes } from '../../../../../components/Widget/types/common';

interface ITemplateModalProps {
  onClose: () => void;
  template: TemplateType | null;
  selected: {
    name: string;
    id: string;
    dataType: DataType;
    widgetType?: WidgetTypes;
  };
}

const initialValues = {
  name: '',
  createDate: '',
  contacts: [],
  source: {
    id: '',
    frequency: '',
  },
  schedule: {
    days: [],
    calculateDays: [],
  },
  type: typeOptions[0].value,
};

const AddEditTemplate = ({
  onClose,
  template,
  selected,
}: ITemplateModalProps) => {
  const currentDate = moment().format(dateTimeSeparatedFormat);
  const sourceType =
    selected.dataType === DataType.Dashboard ? 'DASHBOARD' : 'WIDGET';
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingEditTemplate);
  const [form] = Form.useForm();

  const currentUser = useSelector(selectCurrentUser);

  const [contacts, setContacts] = useState<string[]>(
    (template?.contacts || []).map(el => el.value),
  );
  const [selectedFrequency, setFrequency] = useState<Frequency>(
    template?.schedule.frequency || Frequency.DAY,
  );

  const myName = `${currentUser?.profile.firstName} ${currentUser?.profile.lastName}`;

  const author = template?.author || myName || '';

  const createDate = template?.createDate
    ? moment(template?.createDate).format(dateTimeSeparatedFormat)
    : currentDate;

  const handleFinish = (values: CreateEditTemplateType) => {
    const daysFiltered = values.schedule.days?.filter(
      el => el !== MonthPeriods.LAST_DAY,
    );

    const calculateDays = values.schedule.days?.filter(
      el => el === MonthPeriods.LAST_DAY,
    ) as MonthPeriods[];

    const formattedValue = {
      ...template,
      ...values,
      schedule: {
        ...values.schedule,
        calculateDays,
        days: daysFiltered,
        frequency: selectedFrequency,
        time: moment(values.schedule.time).format(fullTimeFormat),
      },
      author,
      contacts: contacts.map(el => ({ value: el })),
      source: {
        id: selected.id,
        type: sourceType,
      },
    };

    dispatch(
      createAndEditTemplate(formattedValue, () => {
        dispatch(fetchTemplates(selected.id));
        onClose();
      }),
    );
  };

  const handleFrequencyChange = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
    form.setFieldValue(['schedule', 'days'], []);
  };

  const defaultValues = {
    ...initialValues,
    ...template,
    schedule: {
      ...template?.schedule,
      time: template?.schedule.time
        ? moment(template?.schedule.time, fullTimeFormat)
        : '',
    },
  };

  useEffect(() => {
    if (!template) {
      form.resetFields();
      setContacts([]);
    }
  }, [template]);

  return (
    <Form
      className={styles.formContainer}
      onFinish={handleFinish}
      initialValues={defaultValues}
      form={form}
    >
      <div className={styles.section}>
        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage id="widgetForm.Name" defaultMessage="Название" />
          </span>
          <Form.Item className={styles.formItem} name="name">
            <Input disabled={loading} />
          </Form.Item>
        </div>
        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage id="Common.Author" defaultMessage="Автор" />:
          </span>
          <span className={styles.info}>{author}</span>
        </div>
        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.CreationDate"
              defaultMessage="Дата создания"
            />
            :
          </span>
          <span className={styles.info}>{createDate}</span>
        </div>
      </div>
      <div className={styles.section}>
        <EmailSelect
          disabled={loading}
          value={contacts}
          setValue={setContacts}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>
          <FormattedMessage
            id="Common.SendingFormat"
            defaultMessage="Формат отправления"
          />
        </div>
        <Form.Item className={styles.formItem} name="type">
          <Radio.Group disabled={loading} defaultValue={typeOptions[0].value}>
            {typeOptions.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>
          <FormattedMessage
            id="Common.SendingFrequency"
            defaultMessage="Периодичность отправления"
          />
        </div>
        <Form.Item className={styles.formItem} name={['schedule', 'frequency']}>
          <Radio.Group
            disabled={loading}
            onChange={handleFrequencyChange}
            defaultValue={frequencyOptions[0].value}
          >
            {frequencyOptions.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.SendingTime"
              defaultMessage="Время отправления"
            />
          </span>
          <Form.Item className={styles.formItem} name={['schedule', 'time']}>
            <TimePicker
              disabled={loading}
              format={hoursMinutesFormat}
              className={styles.input}
            />
          </Form.Item>
        </div>
        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.SendingDay"
              defaultMessage="День отправления"
            />
          </span>
          <Form.Item
            style={{ width: '50%' }}
            className={styles.formItem}
            name={['schedule', 'days']}
          >
            {selectedFrequency === Frequency.DAY ? (
              <span className={styles.info}>
                <FormattedMessage
                  id="Common.everyDay"
                  defaultMessage="Каждый день"
                />
              </span>
            ) : (
              <Select
                disabled={loading}
                style={{ marginLeft: 10 }}
                mode="multiple"
                className={styles.input}
                allowClear
                options={
                  selectedFrequency === Frequency.MONTH ? days : weekDaysOptions
                }
              />
            )}
          </Form.Item>
        </div>
      </div>
      <div className={styles.submitTemplateBtn}>
        <Button loading={loading} size="large" htmlType="submit" type="primary">
          {template ? (
            <FormattedMessage
              id="Common.SaveChanges"
              defaultMessage="Сохранить изменения"
            />
          ) : (
            <FormattedMessage
              id="SendingTemplate.Add"
              defaultMessage="Добавить шаблон"
            />
          )}
        </Button>
      </div>
    </Form>
  );
};

export default AddEditTemplate;
