import React, { ReactText, useEffect, useMemo, useState } from 'react';
import { Button, Empty, Table, TableProps } from 'antd';
import {
  addFiltersToColumns,
  addKeys,
  filteredColumns,
  generateColumns,
} from './helpers';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './TableWidget.module.less';
import { TablePaginationConfig } from 'antd/es/table';
import { TableWidgetConfig, TableWidgetData } from '../../types/table';
import {
  TableCurrentDataSource,
  TableRowSelection,
} from 'antd/es/table/interface';
import { PlainObject } from '../../../../types/common';
import { getDefaultColumns } from '../../helper';

type TableDataSource = TableWidgetData & { key: string };

interface NestedTableWidgetProps {
  data: Array<TableDataSource>;
  config: TableWidgetConfig;
  parameter: ReactText[] | string;
  onChange: (value: ReactText[]) => void;
}

const defaultPageSize: number = 10;

const TableWidget = ({
  data,
  config,
  parameter,
  onChange,
}: NestedTableWidgetProps) => {
  const paginationProps: TablePaginationConfig = {
    hideOnSinglePage: true,
    position: ['bottomCenter'],
    size: 'small',
    pageSize: config.maxRowCount || defaultPageSize,
  };

  const dataWithKeys = useMemo(() => addKeys(data || []), [data]);

  const [currentDataSource, setCurrentDataSource] = useState<PlainObject[]>([]);

  useEffect(() => {
    setCurrentDataSource(dataWithKeys);
  }, [dataWithKeys]);

  const [keys, setKeys] = useState<string[]>([]);
  const setColumnKeys = (key: string) => {
    setKeys(
      keys.includes(key) ? keys.filter(el => el !== key) : [...keys, key],
    );
  };

  const columns = config.columns || getDefaultColumns(currentDataSource);
  const filtered = filteredColumns(columns, keys);
  const generatedColumns = generateColumns(filtered, keys, setColumnKeys);

  const columnsWithFilters = addFiltersToColumns(
    generatedColumns,
    currentDataSource,
    dataWithKeys,
  );

  const selectedRowKeys: ReactText[] =
    typeof parameter === 'string' ? [parameter] : parameter;

  const rowSelection: TableRowSelection<any> | undefined = config.rowSelection
    ? {
        selectedRowKeys,
        onChange,
        type: config.rowSelectionType || 'checkbox',
      }
    : undefined;

  const handleChange = (
    _: any,
    __: any,
    ___: any,
    extra: TableCurrentDataSource<NestedTableWidgetProps>,
  ) => {
    setCurrentDataSource(extra.currentDataSource);
  };

  const expandIcon: TableProps<NestedTableWidgetProps>['expandIcon'] = ({
    expanded,
    onExpand,
    record,
    expandable,
  }) => {
    if (expandable) {
      return expanded ? (
        <Button
          size="small"
          shape="default"
          className={styles.expandButton}
          onClick={e => onExpand(record, e)}
          icon={<MinusOutlined />}
        />
      ) : (
        <Button
          size="small"
          shape="default"
          className={styles.expandButton}
          onClick={e => onExpand(record, e)}
          icon={<PlusOutlined />}
        />
      );
    }
  };

  if (!data || !data.length) {
    return (
      <Empty
        className={styles.emptyData}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }

  return (
    <Table
      rowSelection={rowSelection}
      pagination={paginationProps}
      bordered={config.bordered}
      size={config.size}
      expandable={{ expandIcon }}
      columns={columnsWithFilters}
      onChange={handleChange}
      dataSource={currentDataSource}
    />
  );
};

export default TableWidget;
