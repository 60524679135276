export const debounceCall = <F extends (...args: any[]) => any>(
  callback: F,
  timeout: number,
) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => callback(...args), timeout);
  };
};
