import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AxiosError } from 'axios';
import { getErrorKey, isTokenError } from '../../utils/errors';

export interface ErrorState {
  error: string | null;
  errorMessage: string | null;
}

const initialState: ErrorState = {
  error: null,
  errorMessage: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state: ErrorState, action: PayloadAction<AxiosError | null>) => {
      const error = action.payload;

      if (error && !isTokenError(error)) {
        state.error = getErrorKey(error);
        state.errorMessage = error.response?.data?.problem
          ? error.response?.data?.problem?.title ||
            error.response?.data?.problem?.message
          : null;
      } else {
        state.error = null;
      }
    },
    clearError: (state: ErrorState) => {
      state.error = null;
      state.errorMessage = null;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export const getError = (state: RootState) => state.error.error;
export const getErrorMessage = (state: RootState) => state.error.errorMessage;

export default errorSlice.reducer;
