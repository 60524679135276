import moment from 'moment/moment';
import { intl } from '../../../../../utils/intl';
import { FileType, Frequency, MonthPeriods } from '../../../types/dashboard';

export const lastDayLabel = intl.formatMessage({
  id: 'Common.LastMonthDay',
  defaultMessage: 'Последний день месяца',
});

const amountOfDays = moment().endOf('month').date() + 1;
export const days = Array(amountOfDays)
  .fill(null, 0, amountOfDays)
  .map((_, i) =>
    i === amountOfDays - 1
      ? { value: MonthPeriods.LAST_DAY, label: lastDayLabel }
      : { value: i + 1, label: (i + 1).toString() },
  );

const onceLabel = intl.formatMessage({
  id: 'Common.OneTimeSending',
  defaultMessage: 'Разовая отправка',
});
const templateLabel = intl.formatMessage({
  id: 'Common.SendingTemplates',
  defaultMessage: 'Шаблоны рассылок',
});

export const tabs = [
  {
    id: 'once',
    name: onceLabel,
  },
  {
    id: 'template',
    name: templateLabel,
  },
];

const dailyLabel = intl.formatMessage({
  id: 'Common.Daily',
  defaultMessage: 'Ежедневно',
});
const weeklyLabel = intl.formatMessage({
  id: 'Common.Week',
  defaultMessage: 'Неделя',
});
const monthlyLabel = intl.formatMessage({
  id: 'Common.Month',
  defaultMessage: 'Месяц',
});

export const frequencyOptions = [
  {
    value: Frequency.DAY,
    label: dailyLabel,
  },
  {
    value: Frequency.WEEK,
    label: weeklyLabel,
  },
  {
    value: Frequency.MONTH,
    label: monthlyLabel,
  },
];

export interface TypeOption {
  value: FileType;
  label: string;
}

export const typeOptions: Array<TypeOption> = [
  {
    value: FileType.PNG,
    label: '.png',
  },
  {
    value: FileType.PDF,
    label: '.pdf',
  },
  {
    value: FileType.XLSX,
    label: '.xlsx',
  },
];

const monday = intl.formatMessage({
  id: 'weekdays.monday',
  defaultMessage: 'Понедельник',
});

const tuesday = intl.formatMessage({
  id: 'weekdays.tuesday',
  defaultMessage: 'Вторник',
});

const wednesday = intl.formatMessage({
  id: 'weekdays.wednesday',
  defaultMessage: 'Среда',
});

const thursday = intl.formatMessage({
  id: 'weekdays.thursday',
  defaultMessage: 'Четверг',
});

const friday = intl.formatMessage({
  id: 'weekdays.friday',
  defaultMessage: 'Пятница',
});

const saturday = intl.formatMessage({
  id: 'weekdays.saturday',
  defaultMessage: 'Суббота',
});

const sunday = intl.formatMessage({
  id: 'weekdays.sunday',
  defaultMessage: 'Воскресенье',
});

export const weekDaysOptions = [
  { label: monday, value: 1 },
  { label: tuesday, value: 2 },
  { label: wednesday, value: 3 },
  { label: thursday, value: 4 },
  { label: friday, value: 5 },
  { label: saturday, value: 6 },
  { label: sunday, value: 7 },
];
