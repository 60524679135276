import moment from 'moment/moment';

export const widgetDateFormat = 'YYYY-MM-DD';
export const birthDateFormat = 'DD.MM.YYYY';
export const isoDateFormat = 'YYYY-MM-DD';
export const serverDateFormat = 'YYYY-MM-DD HH:mm:ss';
export const lastEntranceDateFormat = 'DD.MM.YYYY HH:mm';
export const fullTimeFormat = 'HH:mm:ss';
export const serverDateFullTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateTimeSeparatedFormat = 'DD.MM.YYYY | HH:mm';
export const hoursMinutesFormat = 'HH:mm';
export const formatWithTimezone = (
  date: moment.MomentInput,
  format: string,
  initialFormat: string,
) => {
  const offset = moment(date, initialFormat).utcOffset();
  return moment(date, initialFormat).add(offset, 'minutes').format(format);
};
