import React, { ReactNode } from 'react';
import { Avatar, List } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import _ from 'lodash';
import urlJoin from 'url-join';
import { Link } from 'react-router-dom';
import { IntlShape, useIntl } from 'react-intl';
import { DashboardListItem } from '../../types/dashboard';
import AddDashboardButton from './AddDashboardButton';
import DashSkeleton from '../../../../components/DashSkeleton';
import EmptyList from './EmptyList';
import DeleteDashboardBtn from './DeleteDashboardBtn';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { Permissions } from '../../../settings/types/user';
import { hasAccess } from '../../../../utils/rights';

interface Props {
  dashboards: DashboardListItem[];
  loading: boolean;
  basePath: string;
}

const renderDescription = (
  dashboard: DashboardListItem,
  intl: IntlShape,
): string => {
  return intl.formatMessage(
    {
      id: 'app.DashboardDescription',
      defaultMessage:
        'Дашборд содержит {count} {count, plural, one {виджет} two {виджета} =3 {виджета} =4 {виджета} other {виджетов}}',
      description: 'Dashboard list item description',
    },
    { count: dashboard.widgetCount },
  );
};

const renderTitle = (
  dashboard: DashboardListItem,
  basePath: string,
  intl: IntlShape,
): ReactNode => {
  const url = urlJoin(basePath, dashboard.id);
  const deleteDashTitle = intl.formatMessage({
    id: 'dashboard.Delete',
    defaultMessage: 'Удалить дашборд',
  });

  return (
    <div style={{ wordBreak: 'break-all' }}>
      <Link to={url}>{dashboard.name}</Link>
      <DeleteDashboardBtn title={deleteDashTitle} dashboard={dashboard} />
    </div>
  );
};

const DashList: React.FC<Props> = ({ dashboards, loading, basePath }) => {
  const intl = useIntl();
  const grants = useSelector(selectCurrentUserGrants);
  const addDashPermission = hasAccess([Permissions.DASHBOARD_EDIT], grants);

  if (loading) return <DashSkeleton />;

  if (_.isEmpty(dashboards)) {
    return <EmptyList />;
  }

  const itemRenderer = (item: DashboardListItem) => (
    <List.Item.Meta
      avatar={<Avatar icon={<BarChartOutlined />} />}
      title={renderTitle(item, basePath, intl)}
      description={renderDescription(item, intl)}
    />
  );

  return (
    <List
      loading={loading}
      size="large"
      header={addDashPermission && <AddDashboardButton />}
      dataSource={dashboards}
      renderItem={itemRenderer}
    />
  );
};

export default DashList;
